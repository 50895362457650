import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../data.service';
import {LanguageService} from "../language.service";

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['navigation.component.scss']
})
export class NavigationComponent implements OnInit {

    public ui: any;
    public ui_ready: boolean = false;

    /**
     * c'tor
     * @param {Router} router
     * @param {ActivatedRoute} route
     * @param {DataService} data_service
     */
    constructor(private router: Router,
                private route: ActivatedRoute,
                private data_service: DataService,
                private langage: LanguageService) {
    }

    /**
     * on init lifecycle hook
     */
    ngOnInit() {

        if (this.data_service.ui_ready) {
            this.ui = this.data_service.getUITranslation();
            this.ui_ready = true;
        } else {
            this.data_service.onUILoaded.subscribe(
                (data) => {
                    this.ui = data;
                    this.ui_ready = true;
                }
            );
        }
    }

    public languageDisabled(lang: string): boolean {
        return lang !== this.langage.getLanguage();
    }

}
