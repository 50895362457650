import {Component, OnInit} from '@angular/core';
import {DataService} from '../data.service';
import {Router} from '@angular/router';
import {LanguageService} from '../language.service';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

    public data_ready: boolean = false;
    public data: any;

    /**
     * c'tor
     * @param {DataService} data_service
     * @param {Router} router
     */
    constructor(private data_service: DataService,
                private router: Router,
                private language_service: LanguageService) {
    }

    /**
     * on init lifecycle hook
     */
    ngOnInit() {
        if (this.data_service.data_ready) {
            this.data = this.data_service.getDatabase();
            this.data_ready = true;
        } else {
            this.data_service.onDBLoaded
                .subscribe((data) => {
                    this.data = this.data_service.getDatabase();
                    this.data_ready = true;
                });
        }
    }

    /**
     * Routes to Detail view by selected id
     * @param {string} id
     */
    public openDetail(id: string) {
        const url = ['l', this.language_service.getLanguage(), 'detail', id];
        this.router.navigate(url);
    }


}
