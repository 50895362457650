import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {LanguageService} from '../language.service';
import {DataService} from '../data.service';

@Component({
    selector: 'app-language',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {

    /**
     * c'tor
     * @param {ActivatedRoute} route
     * @param {LanguageService} language_service
     * @param {DataService} data_service
     */
    constructor(private route: ActivatedRoute,
                private language_service: LanguageService,
                private data_service: DataService,
                private router: Router) {
    }

    ngOnInit() {
        this.route.params.forEach((param: Params) => {
            const lang = param['language'];
            if (lang) {
                this.language_service.setLanguage(lang);
                this.initialize(lang);
            } else {
                this.language_service.setLanguage('de');
                this.initialize('de');
            }
        });

    }

    /**
     * Loads the data according to the current set language
     * @param {string} lang
     */
    private initialize(lang: string): void {

        this.data_service.loadDatabaseByLanguage(lang).subscribe();
        this.data_service.loadUITranslation(lang).subscribe();

        // this is bad, always redirects to home on page load, not regarding page path
        // I substituted this with a case for an empty routing directive after language information
        /*this.data_service.onUILoaded.subscribe(() => {
            this.router.navigate(['l', lang, 'home']);
        });*/
    }

}
