import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LanguageService} from "../language.service";

@Component({
    selector: 'app-reload',
    templateUrl: './reload.component.html',
    styleUrls: ['./reload.component.scss']
})
export class ReloadComponent implements OnInit {

    constructor(private router: Router,
                private route: ActivatedRoute,
                private language_service: LanguageService) {
    }

    ngOnInit() {
        this.route.params.subscribe(
            (params) => {
                const id = params['id'];
                const link = ['l', this.language_service.getLanguage(), 'detail', id];
                this.router.navigate(link);
            }
        );

    }

}
