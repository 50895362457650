import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DetailComponent} from './detail/detail.component';
import {HomeComponent} from './home/home.component';
import {LanguageComponent} from './language/language.component';
import {ContentComponent} from './content/content.component';
import {ReloadComponent} from './reload/reload.component';
import {MapComponent} from './map/map.component';
import {ListComponent} from './list/list.component';

// redirectTo: 'home',
const routes: Routes = [
    {
        path: '',
        redirectTo: 'l/de',
        pathMatch: 'full'
    },
    {
        path: 'l/:language',
        component: LanguageComponent,
        children: [
            {
                        path: '',
                        redirectTo: 'home',
                        pathMatch: 'full'
            },
            {
                path: 'home',
                component: HomeComponent,
                children: [
                    {
                        path: '',
                        redirectTo: 'map',
                        pathMatch: 'full'
                    },
                    {
                        path: 'map',
                        component: MapComponent
                    },
                    {
                        path: 'list',
                        component: ListComponent
                    }
                ]

            },
            {
                path: 'detail/:id',
                component: DetailComponent
            },
            {
                path: 'pages/:page',
                component: ContentComponent
            },
            {
                path: 'reload/:id',
                component: ReloadComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes/*, {enableTracing: true}*/)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

