import {Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable()
export class LanguageService {

    public current_language: string;
    public language_changed = new Subject<string>();

    constructor() {
    }

    public setLanguage(lang: string): void {
        this.current_language = lang;
        this.language_changed.next(lang);
    }

    public getLanguage(): string {
        return this.current_language;
    }
}
