import {Component, OnInit} from '@angular/core';
import {DataService} from '../data.service';
import {ActivatedRoute} from '@angular/router';
import {LanguageService} from '../language.service';

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

    public include_file: string;
    public current_language: string;

    /**
     * c'tor
     */
    constructor(private data_service: DataService,
                private route: ActivatedRoute,
                private language_service: LanguageService) {
    }

    /**
     * on init
     */
    ngOnInit() {
        this.route.params.subscribe((params) => {

            const page = params['page'];
            this.current_language = this.language_service.getLanguage();
            if (this.current_language != 'de') {
                const file_parts = page.split('.');
                this.include_file = file_parts[0] + "_en." + file_parts[1];
            } else {
                this.include_file = page;
            }
        });
    }


}
