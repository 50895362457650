import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {initialState} from "../../node_modules/ngx-bootstrap/timepicker/reducer/timepicker.reducer";

@Injectable({
    providedIn: 'root'
})
export class RouteChangeService {

    private initialized = false;
    public onRouteChanged: BehaviorSubject<string>;

    constructor() {
    }

    public routeChanged(route: string) {
        const route_parts = route.split('/');
        const route_end = route_parts[route_parts.length - 1];

        if (!this.initialized) {
            this.onRouteChanged = new BehaviorSubject(route_end);
            this.initialized = true;
        } else {
            this.onRouteChanged.next(route_end);
        }
    }
}
