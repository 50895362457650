import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';

@Injectable()
export class DataService {

    // private members
    private base_url: string;
    private database: any;
    private ui_translation: any;
    private marker: any[] = [];


    // ready states
    public data_ready = false;
    public ui_ready = false;

    // public members
    public onDBLoaded = new Subject<any>();
    public onUILoaded = new Subject<any>();
    public onMarkerLoaded = new Subject<any>();


    /**
     * c'tor
     * @param http
     */
    constructor(private http: HttpClient) {
    }


    /**
     * Loads a dataset by specified language
     * @param {string} language
     * @return {Observable<Object>}
     */
    public loadDatabaseByLanguage(language: string): Subject<any> {

        this.data_ready = false;

        const url = environment.paths.data_base + language + '/data.json';
        this.http.get(url).subscribe(
            (data: any[]) => {
                this.database = data;

                this.marker = [];
                for (const item of data) {
                    const m = {
                        id: item.id,
                        lat: item.geo_lat,
                        long: item.geo_long,
                        title: item.title
                    };
                    this.marker.push(m);
                }

                this.onMarkerLoaded.next(this.marker);
                this.onDBLoaded.next(data);
                this.data_ready = true;
            }, this.handleError
        );

        return this.onDBLoaded;
    }

    /**
     * Loads UI translation files
     * @param {string} language
     * @return {Subject<any>}
     */
    public loadUITranslation(language: string): Subject<any> {
        this.ui_ready = false;
        const url = environment.paths.data_base + language + '/ui.json';
        this.http.get(url).subscribe(
            (data) => {
                this.ui_translation = data;
                this.onUILoaded.next(data);
                this.ui_ready = true;
            }
        );

        return this.onUILoaded;
    }

    /**
     * error handling function
     * @param error
     * @returns {ErrorObservable}
     */
    private handleError(error: any) {
        // In a real world app, we might use a remote logging infrastructure
        // We'd also dig deeper into the error to get a better message
        const errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error(errMsg); // log to console instead
        return Observable.throw(errMsg);
    }


    /**
     * returns the database
     * @returns {any}
     */
    public getDatabase(): any {
        return this.database;
    }

    public getUITranslation(): any {
        return this.ui_translation;
    }

    public getMarker(): any {
        return this.marker;
    }


}

