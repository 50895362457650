import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AccordionModule, AlertModule, CollapseModule, ModalModule, TabsModule} from 'ngx-bootstrap';
import {DataService} from './data.service';
import {NavigationComponent} from './navigation/navigation.component';
import {HeadComponent} from './head/head.component';
import {FooterComponent} from './footer/footer.component';
import {SearchComponent} from './search/search.component';
import {ListComponent} from './list/list.component';
import {DetailComponent} from './detail/detail.component';
import {IncludeHtmlDirective} from './include-html.directive';
import {ContentComponent} from './content/content.component';
import {HttpClientModule} from '@angular/common/http';
import {HomeComponent} from './home/home.component';
import {MapComponent} from './map/map.component';
import {LanguageComponent} from './language/language.component';
import {LanguageService} from './language.service';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import { AccordionWrappperComponent } from './accordion-wrappper/accordion-wrappper.component';
import { ReloadComponent } from './reload/reload.component';
import {RouteChangeService} from './route-change.service';

@NgModule({
    declarations: [
        AppComponent,
        NavigationComponent,
        HeadComponent,
        FooterComponent,
        SearchComponent,
        ListComponent,
        DetailComponent,
        IncludeHtmlDirective,
        ContentComponent,
        HomeComponent,
        MapComponent,
        LanguageComponent,
        AccordionWrappperComponent,
        ReloadComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        AlertModule.forRoot(),
        CollapseModule,
        AccordionModule.forRoot(),
        TabsModule.forRoot(),
        LeafletModule.forRoot(),
        ModalModule.forRoot()
    ],
    providers: [DataService, LanguageService, RouteChangeService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
