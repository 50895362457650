import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataService} from '../data.service';
import {LanguageService} from '../language.service';
import {componentFactoryName} from '@angular/compiler';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {RouteChangeService} from '../route-change.service';
import {AnimationDslVisitor} from '../../../node_modules/@angular/animations/browser/src/dsl/animation_dsl_visitor';
import {Subscribable, Subscription} from 'rxjs';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

    public ui: any;
    public ui_ready = false;

    @ViewChild('mapbtn') map_button: ElementRef;
    @ViewChild('listbtn') list_button: ElementRef;

    private ui_loaded_subscription: Subscription;
    private route_changed_subscription: Subscription;

    /**
     * c'tor
     * @param data_service
     * @param language_service
     * @param route
     * @param router
     * @param route_change
     */
    constructor(private data_service: DataService,
                private language_service: LanguageService,
                private route: ActivatedRoute,
                private router: Router,
                private route_change: RouteChangeService) {
    }

    /**
     * on init
     */
    ngOnInit() {
        if (this.data_service.ui_ready) {
            this.ui = {};
            this.ui = this.data_service.getUITranslation();
            this.ui_ready = true;
        } else {
            this.ui_loaded_subscription = this.data_service.onUILoaded.subscribe(
                (data) => {
                    this.ui = {};
                    this.ui = data;
                    this.ui_ready = true;
                },
                (error) => {
                    console.log(error);
                }
            );

        }

        this.route_change.onRouteChanged.subscribe((route: string) => {
            if (route === 'map') {
                this.map_button.nativeElement.classList.add('active');
                this.list_button.nativeElement.classList.remove('active');
            } else if (route === 'list') {
                this.list_button.nativeElement.classList.add('active');
                this.map_button.nativeElement.classList.remove('active');
            }
        });

    }

    ngOnDestroy() {
        this.ui_ready = false;
        // this.ui_loaded_subscription.unsubscribe();
    }

    /**
     * Loads the information page in the respective applications language
     * @param path
     * @param page
     */
    public getCurrentLanguagePage(path: string, page: string): string {
        const link_parts = page.split('.');
        return path + link_parts[0] + '_' + this.language_service.getLanguage() + '.html';
    }

    public getTabTranslation(selector: string): string {
        return this.ui.home.tabs[selector].toUpperCase();
    }


}
