import {ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {DataService} from '../data.service';
import {tileLayer} from 'leaflet';
import {latLng} from 'leaflet';
import {latLngBounds} from 'leaflet';
import {marker} from 'leaflet';
import {divIcon} from 'leaflet';
import {icon} from 'leaflet';
import {Map} from 'leaflet';
import {Layer} from 'leaflet';
import {ActivatedRoute, Router} from '@angular/router';
import {LanguageService} from '../language.service';
import {MapMarkerService} from "../map-marker.service";
import {isNullOrUndefined} from "util";
import {environment} from "../../environments/environment";
import {migrateLegacyGlobalConfig} from "../../../node_modules/@angular/cli/utilities/config";

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {

    private south_west_boundary = latLng(47.220, 5.801);
    private north_east_boundary = latLng(55.154, 15.073);

    private marker: any;
    public map_ready = false;
    public marker_layer: Layer[] = [];

    public center = latLng(51.608924276511885, 10.932720996093753);

    public map_options = {
        layers: [
            tileLayer(
                environment.map.tile_base,
                {
                    maxZoom: 14,
                    attribution: '...',
                    errorTileUrl: environment.map.error_tile
                }
            )
        ],
        center: latLng(51.608924276511885, 10.932720996093753),
        zoom: 10,
        minZoom: 8,
        maxZoom: 14,
        maxBounds: latLngBounds(this.south_west_boundary, this.north_east_boundary)

    };

    private data_subscription;
    private marker_subscription;

    constructor(private data_service: DataService,
                private router: Router,
                private language: LanguageService,
                private marker_service: MapMarkerService,
                private ng_zone: NgZone) {
    }

    ngOnInit() {
        this.initialize();
    }

    ngOnDestroy() {
        this.marker_layer = [];
        if (!isNullOrUndefined(this.data_subscription)) {
            this.data_subscription.unsubscribe();
        }

        if (!isNullOrUndefined(this.marker_subscription)) {
            this.marker_subscription.unsubscribe();
        }
    }

    private initialize(): void {

        this.language.language_changed.subscribe((lang: string) => {
            // console.log(this.ng_zone);
            // this.ng_zone.run(() => {
            //     this.marker_layer = [];
            //     this.initializeMarker();
            // });
        });

        if (this.data_service.data_ready) {
            this.marker = this.data_service.getMarker();
            this.initializeMarker();
        } else {
            this.data_subscription = this.data_service.onMarkerLoaded.subscribe(
                (data) => {
                    this.marker = data;
                    this.initializeMarker();
                },
                (error) => {
                    console.log('ERROR:', error);
                }
            );
        }

        this.marker_subscription = this.marker_service.onMarkerChange.subscribe(
            (data: string[]) => {
                // console.log(" > Marker change subscription.");
                if (!isNullOrUndefined(this.marker)) {
                    this.marker_layer = [];
                    for (const item of this.marker) {
                        if (item.id == data[0]) {
                            this.center = latLng(item.lat, item.long);
                        }
                        for (const id of data) {
                            if (item.id == id) {
                                this.createMarker(item);
                            }
                        }
                    }

                }

            }
        );

    }


    private initializeMarker(): void {
        this.marker_layer = [];
        for (const m of this.marker) {
            this.createMarker(m);
        }
        // console.log(this.marker_layer);
        this.map_ready = true;
    }

    private createMarker(data: any): any {
        const id: string = data.id;
        const title: string = data.title;
        const coords = [data.lat, data.long];

        const marker_label = marker(
            coords,
            {
                icon: divIcon({
                    className: 'poi-marker-label',
                    html: title,
                    iconAnchor: [60, -5],
                    iconSize: [120, 30]
                }),
                draggable: false
            }
        );

        // console.log("  > creating marker: ", title);

        marker_label.on('click', (event: MouseEvent) => {
            this.routeToDetail(id);
        });


        const marker_image = marker(
            coords,
            {
                icon: icon({
                    iconUrl: 'assets/map/marker.svg',
                    iconSize: [24, 40],
                    iconAnchor: [12, 40]
                }),
                draggable: false
            });

        marker_image.on('click', (event: MouseEvent) => {
            this.routeToDetail(id);
        });

        this.marker_layer.push(marker_label);
        this.marker_layer.push(marker_image);
    }

    private routeToDetail(id: string): void {
        const link = ['/l', this.language.getLanguage(), 'detail', id];
        this.ng_zone.run(() => this.router.navigate(link));
    }

}
