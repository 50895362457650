// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    paths: {
        data_base: 'assets/data/',
        image_base: 'assets/data/images/',
        pages_base: 'assets/html'
    },
    map: {
        tile_base: 'https://www.aussenlager.dora.de/map/{z}/{x}/{y}.png',
        error_tile: 'https://www.aussenlager.dora.de//map/error_tile.png'
    },
    production: false
};
