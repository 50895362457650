import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs/index";

@Injectable({
    providedIn: 'root'
})
export class MapMarkerService {

    private marker: any[];
    public onMarkerChange = new Subject<any>();

    constructor() {
    }

    public setMarkerList(data_set: any[]) {
        this.marker = [];
        for (const item of data_set) {
            this.marker.push(item.id);
        }
        this.onMarkerChange.next(this.marker);
    }

    public getMarkerList(): any[] {
        return this.marker;
    }
}
