import {Component, OnInit} from '@angular/core';
import {DataService} from './data.service';
import {RouteChangeService} from './route-change.service';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    constructor(private data_service: DataService,
                private route_change_service: RouteChangeService,
                private router: Router) {
    }

    ngOnInit() {

        this.router.events.subscribe((evt: RouterEvent) => {
            if (evt instanceof NavigationEnd) {
                this.route_change_service.routeChanged(evt.urlAfterRedirects);
            }
        });

    }

}
