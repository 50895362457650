import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Params, ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../data.service';
import {BsModalRef, BsModalService, ModalDirective} from 'ngx-bootstrap';
import {isNullOrUndefined} from 'util';
import {AppConstants} from '../constants';
import {TemplateBindingParseResult} from "@angular/compiler";
import {LanguageService} from "../language.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['detail.component.scss']
})
export class DetailComponent implements OnInit, OnDestroy {

    public data_ready = false;
    public ui_ready = false;

    private selected_id: number;
    public current_dataset: any;
    public ui: any;

    public image_path: string = AppConstants.paths.images;

    private modal_red: BsModalRef;
    public current_image: string = "";
    public current_desc: string = "";

    // todo add media queries
    @ViewChild('lgModal') private modal_element: ModalDirective;
    // @ViewChild('modalimage') private modal_image: ElementRef;

    public accordion_states = [true, false, false, false, false];

    private data_subscription: Subscription;
    private ui_subscription: Subscription;

    /**
     * c'tor
     * @param data_service
     * @param route
     * @param router
     * @param modal_service
     * @param language_service
     */
    constructor(private data_service: DataService,
                private route: ActivatedRoute,
                private router: Router,
                private modal_service: BsModalService,
                private language_service: LanguageService) {
    }

    /**
     * on init lifecycle hook
     */
    ngOnInit() {
        this.route.params.subscribe(() => {
            this.initialize();
        });

    }

    ngOnDestroy() {
        if (!isNullOrUndefined(this.data_subscription)) {
            this.data_subscription.unsubscribe();
        }
        if (!isNullOrUndefined(this.ui_subscription)) {
            this.ui_subscription.unsubscribe();
        }
    }

    /**
     * initialize component
     */
    private initialize(): void {

        // get the current camp ID by parsing the URL's parameters
        this.route.params.forEach((params: Params) => {
            this.selected_id = +params['id'];
        });

        if (this.data_service.data_ready) {
            this.current_dataset = this.findDataset(this.selected_id);
            this.data_ready = true;
        } else {
            this.data_subscription = this.data_service.onDBLoaded.subscribe(
                (data) => {
                    this.current_dataset = this.findDataset(this.selected_id);
                    this.data_ready = true;
                },
                (error) => {
                    console.log(error);
                }
            );
        }

        if (this.data_service.ui_ready) {
            this.ui = this.data_service.getUITranslation();
            this.ui_ready = true;
        } else {
            this.ui_subscription = this.data_service.onUILoaded.subscribe(
                (data: any) => {
                    this.ui = data;
                    this.ui_ready = true;
                }
            );

        }
    }


    /**
     *
     * @param {number} id
     * @return {any}
     */
    private findDataset(id: number): any {
        const data = this.data_service.getDatabase();

        let found;
        for (const item of data) {
            if (item.id == this.selected_id) {
                found = item;
            }
        }

        return found;
    }


    /**
     * open Modal displaying the image
     * @param {TemplateRef<any>} template
     * @param {string} image_title
     */
    public openModal(template: TemplateRef<any>, image_file: string, image_desc: string) {
        this.current_image = image_file;
        this.current_desc = image_desc;
        this.modal_red = this.modal_service.show(template);
    }


    /**
     * check if a property is valid
     * @param dataset
     * @return {boolean}
     */
    public isValid(dataset: any): boolean {
        if (isNullOrUndefined(dataset)) {
            return false;
        } else if (dataset.length < 1 || dataset == '') {
            return false;
        } else {
            return true;
        }
    }

    public back(): void {
        const link = ["l", this.language_service.getLanguage(), 'home', 'map'];
        this.router.navigate(link);
    }

}
