import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {Router} from "@angular/router";
import {DataService} from "../data.service";
import {LanguageService} from "../language.service";
import {MapMarkerService} from "../map-marker.service";
import {markInvalid} from "ngx-bootstrap/chronos/create/valid";

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['search.component.scss'],
    providers: []
})
export class SearchComponent implements OnInit {

// privates
    private ready: boolean;
    public ui_ready: boolean = false;

    public ui: any;

    // search
    public search_results: any[];
    public results_collapsed = true;

    // view elements
    @ViewChild('searchBox') private searchbar: ElementRef;

    /**
     * c'tor
     * @param {Router} router
     * @param {DataService} data_service
     * @param {LanguageService} language_service
     */
    constructor(private router: Router,
                private data_service: DataService,
                private language_service: LanguageService,
                private marker_service: MapMarkerService) {
    }

    /**
     * init lifecycle hook
     */
    ngOnInit() {
        if (this.data_service.data_ready) {
            this.ready = true;
        } else {
            this.data_service.onDBLoaded.subscribe(() => {
                this.ready = true;
            });
        }

        if (this.data_service.ui_ready) {
            this.ui = this.data_service.getUITranslation();
            this.ui_ready = true;
        } else {
            this.data_service.onUILoaded.subscribe(
                (data) => {
                    this.ui = data;
                    this.ui_ready = true;
                }
            );
        }
    }


    /**
     * perform the search
     * @param {string} term
     */
    public search(term: string): void {
        if (this.ready) {
            const data = this.data_service.getDatabase();
            this.search_results = data.filter((item) => {
                    if (item["title"].toUpperCase().startsWith(term.toUpperCase())) {
                        return item;
                    }
                }
            );

            this.marker_service.setMarkerList(this.search_results);
        }
    }

    /**
     * open detail view and put current selected persons name into searchbar
     * @param {number} id
     * @param {string} title
     */
    public openDetailView(id: number, title: string): void {
        this.searchbar.nativeElement.value = title;
        const language = this.language_service.getLanguage();
        const link = ["l", language, 'detail', id];
        this.collapseResultDiv();
        this.router.navigate(link);
    }


    /**
     * if clicked select text in searchbar
     */
    public selectInputTextOnClick(): void {
        this.searchbar.nativeElement.setSelectionRange(0, this.searchbar.nativeElement.value.length);
    }


    /**
     * collapse result div
     */
    public collapseResultDiv(): void {
        this.results_collapsed = true;
    }


    /**
     * if there is content to search for --> expand the result box
     * @param {string} content
     */
    public expandResultDiv(content: string): void {
        this.results_collapsed = !content;
    }


    /**
     * handle keydown events
     * @param event
     */
    public handleKeydown(event: KeyboardEvent): void {
        // if 'ESC' pressed
        if (event.keyCode === 27) {
            this.searchbar.nativeElement.value = "";
            this.collapseResultDiv();
        }
    }

}
