import {Component} from '@angular/core';
import {LanguageService} from '../language.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['footer.component.scss']
})
export class FooterComponent {

    constructor(private language: LanguageService,
                private router: Router) {
    }

    public linkToContent(content: string): void {
        const url = ['l', this.language.getLanguage(), 'pages', content];
        this.router.navigate(url);
    }

}
