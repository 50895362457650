import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AppConstants} from '../constants';
import {applySourceSpanToStatementIfNeeded} from '@angular/compiler/src/output/output_ast';

@Component({
    selector: 'app-accordion-wrappper',
    templateUrl: './accordion-wrappper.component.html',
    styleUrls: ['./accordion-wrappper.component.scss']
})
export class AccordionWrappperComponent implements OnInit {

    @Input() current_dataset: any;
    @Input() heading: string;

    @Input() has_info: boolean;
    @Input() info_data: string;

    @Input() show_if: boolean;
    @Input() is_open: boolean;

    @Output() image_clicked = new EventEmitter<any>();

    @ViewChild('stateicon') private state_icon;

    public image_path = AppConstants.paths.images;

    constructor() {
    }

    ngOnInit() {
    }

    public openChange(event): void {
        this.is_open = event;
        if (event) {
            this.state_icon.nativeElement.className = 'accordion-icon glyphicon glyphicon-minus';
        } else {
            this.state_icon.nativeElement.className = 'accordion-icon glyphicon glyphicon-plus';
        }
    }

    /**
     * @param {string} file
     * @param {string} desc
     */
    public imageClicked(file: string, desc: string): void {
        this.image_clicked.emit({file: file, description: desc});
    }

}
